.admonition {
  position: relative;
  margin: 1rem 0;
  padding: 0 .75rem;
  background-color: map-get($admonition-background-color-map, 'note');
  border-left: .25rem solid map-get($admonition-color-map, 'note');
  overflow: auto;

  .admonition-title {
    font-weight: bold;
    margin: 0 -0.75rem;
    padding: .25rem 1.8rem;
    border-bottom: 1px solid map-get($admonition-background-color-map, 'note');
    background-color: opacify(map-get($admonition-background-color-map, 'note'), 0.15);
  }

  &.open .admonition-title {
    background-color: map-get($admonition-background-color-map, 'note');
  }

  .admonition-content {
    padding: .5rem 0;
  }

  span.icon > svg {
    font-size: 0.85rem;
    color: map-get($admonition-color-map, 'note');
    position: absolute;
    top: .6rem;
    left: .4rem;
  }

  span.details-icon > svg {
    position: absolute;
    top: .6rem;
    right: .3rem;
  }

  @each $type, $color in $admonition-color-map {
    &.#{$type} {
      border-left-color: $color;

      span.icon > svg {
        color: $color;
      }
    }
  }

  @each $type, $color in $admonition-background-color-map {
    &.#{$type} {
      background-color: $color;

      .admonition-title {
        border-bottom-color: $color;
        background-color: opacify($color, 0.15);
      }

      &.open .admonition-title {
        background-color: $color;
      }
    }
  }

  &:last-child {
    margin-bottom: .75rem;
  }
}
