.series-nav {
    margin: .8rem 0;
    &[kept=true] {
        display: block;
    }
    .series-title {
      font-size: $toc-title-font-size;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      line-height: 2em;
      padding: 0 .75rem;
      background: darken($code-background-color, 6%);
  
      [theme=dark] & {
        background: darken($code-background-color-dark, 6%);
      }
      [theme=black] & {
        background: darken($code-background-color-black, 6%);
      }
    }
  
    .series-content {
      font-size: $toc-content-font-size;
      background-color: $code-background-color;
  
      > nav > ul {
        margin: 0;
        padding: .4rem 1rem .4rem 1.8rem;
      }
  
      [theme=dark] & {
        background-color: $code-background-color-dark;
      }
      [theme=black] & {
        background-color: $code-background-color-black;
      }
      ul {
        text-indent: -0.85rem;
        padding-left: .8rem;
        list-style: none;
  
        a:first-child::before {
          content: "|";
          font-weight: bolder;
          margin-right: .5rem;
          color: $single-link-color;
  
          [theme=dark] & {
            color: $single-link-color-dark;
          }
          [theme=black] & {
            color: $single-link-color-black;
          }
        }
        span.active {
            &:first-child::before {
                content: "|";
                margin-right: .5rem;
            }
            font-weight: bolder;
            color: $single-link-color;      
            [theme=dark] & {
              color: $single-link-color-dark;
            }
            [theme=black] & {
              color: $single-link-color-black;
            }
      
            &::before {
              color: $single-link-hover-color;
      
              [theme=dark] & {
                color: $single-link-hover-color-dark;
              }
              [theme=black] & {
                color: $single-link-hover-color-black;
              }
            }
          }
        ul {
          padding-left: 1.5rem;
        }
      }
    }
    &.open {
        .toc-title {
          background: darken($code-background-color, 3%);
    
          [theme=dark] & {
            background: darken($code-background-color-dark, 3%);
          }
          [theme=black] & {
            background: darken($code-background-color-black, 3%);
          }
        }
      }
}
  